<template>
  <el-container>
    <patient-new-form v-model="showNewPatientForm" :patient_id="newPatientId" @after-save="setPatientForSearchForm"></patient-new-form>
    <patient-form v-model="showPatientForm"></patient-form>
    <contact-form v-model="showContactForm"></contact-form>

    <el-dialog v-model="showDownloadWindow">
      <h4><i class="el-icon-download"></i> Ausstehende Meldungen herunterladen</h4>

      <el-card class="mt-3" shadow="false" style="word-break: break-word">
        <div v-if="!preCheckResult"><i style="font-size:2em;" class="el-icon el-icon-loading"></i></div>
        <div v-else>
          Es {{(preCheckResult.valid === 1 ? 'ist' : 'sind')}} <b>{{preCheckResult.valid}}</b> gültige Meldung{{(preCheckResult.valid === 1 ? '' : 'en')}} vorhanden<span v-if="preCheckResult.invalid > 0">, <b>{{preCheckResult.invalid}}</b> Meldung{{(preCheckResult.invalid === 1 ? '' : 'en')}} {{(preCheckResult.invalid === 1 ? 'ist' : 'sind')}} noch fehlerhaft und {{(preCheckResult.invalid === 1 ? 'wird' : 'werden')}} nicht berücksichtigt.</span>
        </div>
        <el-alert :closable="false" style="font-size: 1.2em;" class="mt-3" v-if="afterDownloadMessage">
          <div v-if="afterDownloadMessage.successCount > 0">
            Sie haben die Datei <b>{{afterDownloadMessage.filename}}</b> erhalten.<br/>
            Darin {{(afterDownloadMessage.successCount === 1 ? 'ist' : 'sind')}} <b>{{afterDownloadMessage.successCount}}</b> Meldung{{(afterDownloadMessage.successCount === 1 ? '' : 'en')}} enthalten und wurden in Dacy als erfolgt gekennzeichnet.
          </div>
          <div v-if="afterDownloadMessage.errorCount > 0">
            Bei <b>{{afterDownloadMessage.errorCount}}</b> Meldung{{(afterDownloadMessage.errorCount === 1 ? '' : 'en')}} gab es Fehler:<br/>
            <el-collapse class="el-card el-card__body p-2 mt-2">
              <el-collapse-item title="Fehler anzeigen">
                <div v-for="item in afterDownloadMessage.sequence" :key="item.id">
                  <div v-if="item.error" ><u>Meldung #{{item.entry.id}}</u><br/>{{item.error_message}}</div>
                </div>
              </el-collapse-item>
            </el-collapse>

          </div>
        </el-alert>
      </el-card>

      <el-row class="mt-4">
        <el-col :span="12">
          <el-button @click="showDownloadWindow = false">Abbrechen</el-button>
        </el-col>
        <el-col :span="12" class="text-right pr-2">
          <el-badge :value="(preCheckResult ? preCheckResult.valid : 0)" class="item" type="success">
            <el-button type="warning" @click="onBtnDownloadXml" :disabled="!preCheckResult || preCheckResult.valid === 0"><i class="el-icon-download"></i> oBDS Datei herunterladen</el-button>
          </el-badge>
        </el-col>
      </el-row>

    </el-dialog>

    <el-dialog v-model="showCancerRegistryError">
      <h4><i class="el el-icon-warning text-danger"></i> Mindestens eine Datei enthält Fehler</h4>

        <el-collapse>
          <el-collapse-item v-bind:key="row.id" v-for="row in cancerRegistryErrors" :title="'#' + row.patient.id + ' ' + row.patient.name + ', ' + row.patient.firstname + ', ' + getXmlType(row.type) + ', ' + row.filename">
            <el-carousel :autoplay="false" height="150px" indicator-position="outside" arrow="always">
              <el-carousel-item v-bind:key="error.id" v-for="error in row.xml_error">
                {{error}}
              </el-carousel-item>
            </el-carousel>
          </el-collapse-item>
        </el-collapse>

    </el-dialog>

    <el-dialog v-model="showCurrentEntryModal" width="90%">

      <div v-if="currentEntry.xml_error">
        <h4><i class="el el-icon-warning text-danger"></i> oBDS Format Fehler vorhanden</h4>
        {{currentEntry.xml_error}}
      </div>
      <div v-else-if="Object.keys(currentEntry.meta.errors).length === 0">
        <h4><i class="el el-icon-document-checked text-success"></i> Keine Fehler vorhanden</h4>
      </div>
      <div v-else>

        <h4 class="mb-4"><i class="el el-icon-warning text-danger"></i> Bitte korrigieren Sie die Meldung in {{Object.keys(currentEntry.meta.errors).length}} Bereichen</h4>
        <el-tabs>
          <el-tab-pane v-for="(errors, index) in currentEntry.meta.errors" :label="(index + ' (' + errors.length + ')')" :key="index">

            <el-row>
              <el-col class="mb-3" :span="7" :offset="(index % 3 !== 0 ? 1 : 0)" v-bind:key="row.id" v-for="(row, index) in errors">
                <h6>{{row.label}}</h6>
                <el-select v-if="row.type === 'select'" v-model="row.value" class="w-100">
                  <el-option v-for="option in row.options" :key="(option.id ? option.id : option)" :label="(option.title ? option.title : option)" :value="(option.id ? option.id : option)"></el-option>
                </el-select>
                <el-date-picker
                  v-else-if="row.type === 'date'"
                  class="w-100"
                  v-model="row.value"
                  type="date"
                  value-format="YYYY-MM-DD"
                  format="DD.MM.YYYY"
                  placeholder="Datum auswählen">
                </el-date-picker>
                <el-input v-else v-model="row.value" :disabled="row.unsolvable"></el-input>
                <el-alert :closable="false" v-if="row.unsolvable" type="error" style="word-break: keep-all;">
                  <div v-if="row.customMessage">{{row.customMessage}}</div>
                  <div v-else>Fehler dieser Art können nur im Dacy System aktualisiert werden und benötigen ein Knowledgebase Update</div>
                </el-alert>
              </el-col>
            </el-row>

          </el-tab-pane>
        </el-tabs>
        <el-button class="mt-3" type="primary" @click="onSaveCurrentEntry">Speichern</el-button>

      </div>

    </el-dialog>

    <el-header style="background-color:#FFF;">
      <el-row class="pt-2 pb-2">
        <el-col :xs="11" :sm="9" :span="7" :xl="4" :lg="6">
          <el-button-group>
            <el-tooltip
              content="Patient Stammdaten"
              placement="bottom"
              v-if="currentPatient"
            >
              <el-button @click="showPatientForm = true" icon="el-icon-user-solid" plain></el-button>
            </el-tooltip>

            <el-tooltip
              content="Einstellungen"
              placement="bottom"
            >
              <el-button icon="el-icon-setting" plain @click="redirectToSettingsPage"></el-button>
            </el-tooltip>

          </el-button-group>
        </el-col>
        <el-col :xs="11" :sm="13" :span="11" :xl="8" :lg="10">
          <patient-search :patient_id="currentPatientId"></patient-search>
        </el-col>
        <el-col :xs="0" :sm="0" :span="4" :xl="10" :lg="6">
          &nbsp;
        </el-col>
        <el-col :sm="2" :span="2" class="text-right align-content-end">
          <main-menu></main-menu>
        </el-col>
      </el-row>
    </el-header>
    <div class="w-100" style="background-color:#f39200; height:8px;"></div>
    <el-main>
      <el-card class="box-card">

        <el-row>
          <el-col :span="6">
            <h5>Krebsregister</h5>
          </el-col>
          <el-col class="text-right" :span="18">
              <el-button type="primary" style="margin-right:15px;" @click="afterDownloadMessage = ''; preCheckDownloadFiles()">
                <i class="el-icon-download"></i> Ausstehende Meldungen herunterladen
              </el-button>
          </el-col>
        </el-row>
        <el-row>
          <div class="w-75 d-flex mt-2">
            <el-date-picker
              v-model="filtersDateFrom"
              type="date"
              placeholder="Datum von"
              size="large"
              format="DD.MM.YYYY"
            />
            <span class="ml-2 mr-2 mt-auto mb-auto">-</span>
            <el-date-picker
              v-model="filtersDateTo"
              type="date"
              placeholder="Datum bis"
              size="large"
              format="DD.MM.YYYY"
            />

            <el-select
              v-model="filtersSelectedQuarter"
              placeholder="Quartal"
              size="large"
              class="ml-2"
            >
              <el-option v-for="item in quarterOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>

            <el-select
              class="ml-2"
              v-model="filtersSelectedType"
              placeholder="Typ"
              size="large"
              style="width: 240px"
            >
              <el-option
                v-for="item in typeSelectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </el-row>
        <hr>
        <el-tabs
          type="card"
          v-model="activeTab"
        >
          <el-tab-pane label="Erfolgte Meldungen" name="sent">
            <el-table
              :data="tableDataSent"
              :default-sort = "{prop: 'date', order: 'descending'}"
              style="width: 100%"
              stripe>
              <el-table-column
                prop="id"
                label="ID"
                width="100"
                sortable>
              </el-table-column>
              <el-table-column
                label="Patient-ID"
                width="100">
                <template #default="scope">
                  #{{ scope.row.patient.id }}
                </template>
              </el-table-column>
              <el-table-column
                prop="patient"
                label="Patient"
                sortable>
                <template #default="scope">
                  #{{ scope.row.patient.id }} {{ scope.row.patient.name }}, {{ scope.row.patient.firstname }}
                </template>
              </el-table-column>
              <el-table-column
                prop="date"
                label="Datum"
                sortable>
                <template #default="scope">
                  {{ $filters.germanDate(scope.row.date, true) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="type"
                label="Grund"
                sortable>
                <template #default="scope">
                  {{getXmlType(scope.row.type)}}
                </template>
              </el-table-column>
              <el-table-column
                prop="type"
                label="Fehler"
                width="100"
                sortable>
                <template #default="scope">
                  <el-button @click="loadCancerRegistryEntry(scope.row.id)" v-if="scope.row.xml_error"><i class="el el-icon-warning text-danger"></i></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Ausstehende Meldungen" name="not_sent">
            <el-table
              :data="tableDataNotSent"
              :default-sort = "{prop: 'date', order: 'descending'}"
              style="width: 100%"
              stripe>
              <el-table-column
                prop="id"
                label="ID"
                width="100"
                sortable>
              </el-table-column>
              <el-table-column
                label="Patient-ID"
                width="100">
                <template #default="scope">
                  #{{ scope.row.patient.id }}
                </template>
              </el-table-column>
              <el-table-column
                prop="patient"
                label="Patient"
                sortable>
                <template #default="scope">
                  #{{ scope.row.patient.id }} {{ scope.row.patient.name }}, {{ scope.row.patient.firstname }}
                </template>
              </el-table-column>
              <el-table-column
                prop="date"
                label="Datum"
                sortable>
                <template #default="scope">
                  {{ $filters.germanDate(scope.row.date, true) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="type"
                label="Grund"
                sortable>
                <template #default="scope">
                  {{getXmlType(scope.row.type)}}
                </template>
              </el-table-column>
              <el-table-column
                prop="type"
                label="Fehler"
                width="100"
                sortable>
                <template #default="scope">
                  <el-button @click="loadCancerRegistryEntry(scope.row.id)" v-if="scope.row.status === 'invalid'"><i class="el el-icon-warning text-danger"></i></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane
            label="Auswertung"
            name="evaluation"
          >
            <el-table :data="tableDataEvaluations">
              <el-table-column prop="type" label="Meldung">
                <template #default="scope">
                  <div v-if="scope.$index !== tableDataEvaluations.length - 1">{{scope.row.type}}</div>
                  <strong v-if="scope.$index === tableDataEvaluations.length - 1">Summe</strong>
                </template>
              </el-table-column>
              <el-table-column prop="count" label="Anzahl" header-align="right" align="right">
                <template #default="scope">
                  <div v-if="scope.$index !== tableDataEvaluations.length - 1">
                    {{scope.row.count}}
                  </div>
                  <strong v-if="scope.$index === tableDataEvaluations.length - 1">
                    {{scope.row.totalCount}}
                  </strong>
                </template>
              </el-table-column>
              <el-table-column prop="sum" label="Summe" header-align="right" align="right">
                <template #default="scope">
                  <div v-if="scope.$index !== tableDataEvaluations.length - 1">
                    {{scope.row.sum % 1 ? String(scope.row.sum).replace('.', ',') : String(scope.row.sum) + ",00"}} EUR
                  </div>
                  <div v-if="scope.$index === tableDataEvaluations.length - 1">
                    <strong>{{ scope.row.totalSum % 1 ? String(scope.row.totalSum).replace('.', ',') : String(scope.row.totalSum) + ",00" }} EUR</strong>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>


        <!--
        <hr>

        <div v-if="currentPatient">

          <h6 class="mb-3">XML Meldung generieren für {{fullInfo}}</h6>
          <el-button type="warning" @click="openXMLWindow('therapies')">Therapien</el-button>
          <el-button type="warning" @click="openXMLWindow('progress')">Verläufe</el-button>
          <el-button type="warning" @click="openXMLWindow('death')">Tod</el-button>

        </div>
        -->

      </el-card>

    </el-main>
  </el-container>
</template>

<script>
// import PatientEcog from "@/components/PatientEcog";
import * as moment from "moment"
import PatientNewForm from "@/components/patient/PatientNewForm";
import PatientForm from "@/components/patient/PatientForm";
import ContactForm from "@/components/parts/ContactForm";

import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import MainMenu from "@/components/parts/MainMenu";
import DacyServer from "../dacyserver";
import _ from "lodash";
import socket from "../socket";

export default {
  name: "CancerRegistryDashboard",
  props: [],
  components: {
    MainMenu,
    // PatientEcog,
    PatientNewForm,
    PatientForm,
    ContactForm
  },
  data () {
    return {
      tableDataSent: [],
      tableDataNotSent: [],
      tableDataEvaluations: [],
      currentPatientId: null,
      currentEntry: null,
      newPatientId: null,
      showNewPatientForm: false,
      showPatientForm: false,
      showContactForm: false,
      showCancerRegistryError: false,
      showCurrentEntryModal: false,
      showDownloadWindow: false,
      preCheckResult: null,
      afterDownloadMessage: '',
      currentEntrySolvedErrors: [],
      cancerRegistryErrors: [],
      filtersDateFrom: null,
      filtersDateTo: null,
      filtersSelectedQuarter: null,
      activeTab: "sent",
      filtersSelectedType: "all",
      typeSelectOptions: [
        { value: 'all', label: 'Alle' },
        { value: 'death', label: 'Tod' },
        { value: 'diagnosis', label: 'Diagnose' },
        { value: 'systemtherapy', label: 'Syst. Therapie' },
        { value: 'progress', label: 'Verlauf' },
        { value: 'tumorconference', label: 'Tumorkonferenz' },
        { value: 'radio', label: "Bestrahlung" },
        { value: 'therapy_op', label: "OP" }
      ],
      quarterOptions: [
        { value: 'quarter_1 ' + moment().year(), label: 'Quartal 1 ' + moment().year() },
        { value: 'quarter_2 ' + moment().year(), label: 'Quartal 2 ' + moment().year() },
        { value: 'quarter_3 ' + moment().year(), label: 'Quartal 3 ' + moment().year() },
        { value: 'quarter_4 ' + moment().year(), label: 'Quartal 4 ' + moment().year() },
        { value: 'quarter_1 ' + (moment().year() - 1), label: 'Quartal 1 ' + (moment().year() - 1) },
        { value: 'quarter_2 ' + (moment().year() - 1), label: 'Quartal 2 ' + (moment().year() - 1) },
        { value: 'quarter_3 ' + (moment().year() - 1), label: 'Quartal 3 ' + (moment().year() - 1) },
        { value: 'quarter_4 ' + (moment().year() - 1), label: 'Quartal 4 ' + (moment().year() - 1) }
      ]
    }
  },
  mounted: async function () {
    await this.getCancerregistryEntries()

    socket.on('export-cancerregistry-message', async(data) => {

      console.log("socket", data)
      this.afterDownloadMessage = data
      await this.preCheckDownloadFiles()
      await this.getCancerregistryEntries()
    })
  },
  computed: {
    ...mapGetters('patients', ['fullInfo', 'currentPatient', 'unusedFindings']),
    ...mapGetters('cancerregistry', ['cancerregistryentries', 'cancerregistryCount']),
    ...mapGetters('patients', ['currentPatient'])
  },
  watch: {
    currentPatient: function () {
      this.applyFilters()
    },
    cancerregistryentries: function (val) {
      this.tableDataSent = _.cloneDeep(val).filter(el => el.sent === true)
      this.tableDataNotSent = _.cloneDeep(val).filter(el => el.sent === false)
      this.tableDataEvaluations = _.cloneDeep(val).reduce((a, b) => {
        const indexInArr = a.findIndex(el => el.type === this.getXmlType(b.type) && el.patient_id === b.patient_id);

        if(indexInArr !== -1) {
          a[indexInArr].count += 1;
          a[indexInArr].sum += 9;
        } else {
          a.push({ count: 1, sum: 9, type: this.getXmlType(b.type), patient_id: b.patient_id });
        }
        return a;
      }, []);
      // Added object with total sum and count for additional row in table
      this.tableDataEvaluations.push({ totalSum: this.tableDataEvaluations.reduce((a, b) => b.sum ? a + b.sum : a, 0), totalCount: this.tableDataEvaluations.reduce((a, b) => b.count ? a + b.count : a, 0) });
    },
    filtersSelectedQuarter: function (val) {
      const yearNow = moment().year();

      if(val === 'quarter_1 ' + yearNow) {
        this.filtersDateFrom = yearNow + "-01-01"
        this.filtersDateTo = yearNow + "-03-31"
      }
      if(val === 'quarter_2 ' + yearNow) {
        this.filtersDateFrom = yearNow + "-04-01"
        this.filtersDateTo = yearNow + "-06-30"
      }
      if(val === 'quarter_3 ' + yearNow) {
        this.filtersDateFrom = yearNow + "-07-01"
        this.filtersDateTo = yearNow + "-09-30"
      }
      if(val === 'quarter_4 ' + yearNow) {
        this.filtersDateFrom = yearNow + "-10-01"
        this.filtersDateTo = yearNow + "-12-31"
      }

      if(val === 'quarter_1 ' + (yearNow - 1)) {
        this.filtersDateFrom = yearNow - 1 + "-01-01"
        this.filtersDateTo = yearNow - 1 + "-03-31"
      }
      if(val === 'quarter_2 ' + (yearNow - 1)) {
        this.filtersDateFrom = yearNow - 1 + "-04-01"
        this.filtersDateTo = yearNow - 1 + "-06-30"
      }
      if(val === 'quarter_3 ' + (yearNow - 1)) {
        this.filtersDateFrom = yearNow - 1 + "-07-01"
        this.filtersDateTo = yearNow - 1 + "-09-30"
      }
      if(val === 'quarter_4 ' + (yearNow - 1)) {
        this.filtersDateFrom = yearNow - 1 + "-10-01"
        this.filtersDateTo = yearNow - 1 + "-12-31"
      }
      this.applyFilters()
    },
    filtersDateFrom: function () {
      this.applyFilters()
    },
    filtersDateTo: function () {
      this.applyFilters()
    },
    filtersSelectedType: function () {
      this.applyFilters()
    }
  },
  methods: {
    ...mapActions('patients', ['reloadCurrentPatient', 'setCurrentPatient']),
    ...mapActions('cancerregistry', ['getCancerregistryEntries', 'getCancerregistryEntriesByFilters']),
    clearFilters() {
      this.filtersDateFrom = null
      this.filtersDateTo = null
      this.filtersSelectedType = null
    },
    redirectToSettingsPage() {
      window.location.href = '/app/setting'
    },
    getXmlType(type) {
      if(type === 'death') return "Tod"
      if(type === 'diagnosis') return "Diagnose"
      if(type === 'systemtherapy') return "Syst. Therapie"
      if(type === 'progress') return "Verlauf"
      if(type === 'tumorconference') return "Tumorkonferenz"
      if(type === 'radio') return "Bestrahlung"
      if(type === 'therapy_op') return "OP"
    },
    applyFilters() {
      const filters = {
        dateFrom: this.filtersDateFrom ? moment(this.filtersDateFrom).format("YYYY-MM-DD") : "",
        dateTo: this.filtersDateTo ? moment(this.filtersDateTo).format("YYYY-MM-DD") : "",
        sent: false,
        type: this.filtersSelectedType
      }
      this.getCancerregistryEntriesByFilters(filters)
        .then(() => {

          if(this.currentPatient) {
            this.tableDataNotSent = this.tableDataNotSent.filter(el => this.currentPatient.id === el.patient_id)
            this.tableDataSent = this.tableDataSent.filter(el => this.currentPatient.id === el.patient_id)
            this.tableDataEvaluations = this.tableDataEvaluations.filter(el => this.currentPatient.id === el.patient_id)

            // Added object with total sum and count for additional row in table
            this.tableDataEvaluations.push({ totalSum: this.tableDataEvaluations.reduce((a, b) => b.sum ? a + b.sum : a, 0), totalCount: this.tableDataEvaluations.reduce((a, b) => b.count ? a + b.count : a, 0) });
          }
        })
    },
    async getPatientFromAIS() {
      const result = (await axios({url: 'gdt', baseURL: 'http://127.0.0.1:3333'})).data
      if (result.error === false) {
        const patient = await this.setCurrentPatient(result.patient_id)
        if (patient === null) {
          this.newPatientId = result.patient_id
          this.showNewPatientForm = true
        } else {
          this.currentPatientId = result.patient_id
        }
      }
      else {
        this.$notify.error({
          message: "Ein Fehler trat bei der Kommunikation mit dem AIS auf",
              position: 'bottom-left'
            });
      }
    },
    setPatientForSearchForm() {
      this.currentPatientId = this.currentPatient.id
    },
    async onRowClick(row) {

      const signResult = await axios.get('/sign/export/cancerregistry/single/' + row.id)
      if(signResult) {
        const url = DacyServer.getInstance().getURL() + signResult.data

          if(window.gui) window.gui.send('download-file', url)
          else window.open(url, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))

      }

    },
    async loadCancerRegistryEntry(id) {

      this.currentEntry = (await axios.get('/cancerregistry/' + id)).data
      this.showCurrentEntryModal = true


    },
    async preCheckDownloadFiles() {

      this.showDownloadWindow = true
      this.preCheckResult = null

      try {
        this.preCheckResult = (await axios.get('/export/cancerregistry/unsent/precheck')).data
      }
      catch (e) {
        this.preCheckResult = null
      }
    },
    async onBtnDownloadZip() {

      if(!await this.preCheckDownloadFiles()) return

      const signResult = await axios.get('/sign/export/cancerregistry/unsent/zip')
      if(signResult) {
        const url = DacyServer.getInstance().getURL() + signResult.data
        if(window.gui) window.gui.send('download-file', url)
        else window.open(url, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
      }

    },
    async onBtnDownloadXml() {

      this.afterDownloadMessage = ''
      await this.preCheckDownloadFiles()

      const signResult = await axios.get('/sign/export/cancerregistry/unsent/xml')
      if(signResult) {
        const url = DacyServer.getInstance().getURL() + signResult.data
        //await axios.get(url)
        //return

        if(window.gui) window.gui.send('download-file', url)
        else window.open(url, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))

      }

    },
    async onSaveCurrentEntry() {
      await axios.put('/cancerregistry/' + this.currentEntry.id + "/corrections", this.currentEntry.meta.errors)
      await this.loadCancerRegistryEntry(this.currentEntry.id)
    }


  }

}
</script>
<style scoped>
</style>
